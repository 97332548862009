import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import axios from "axios";
import CopyToClipboard from "react-copy-to-clipboard";
import ReactLoading from "react-loading";
import { useSelector } from "react-redux";
import Navbar from "../../components/Navbar.js";
import Tree from "react-animated-tree-v2";
import { plus, minus, close } from "../../components/icons.js";
import { toastAlert } from "../../helper/toastAlert.js";
import moment from "moment";
import AUTOPOOLV4 from "../../ABI/AUTOPOOLV4.json";
import config from "../../config/config.js";
import ScaleLoader from "react-spinners/ScaleLoader";

const override = {
  margin: "0 auto",
  borderColor: "red",
  position: "fixed",
  top: "50%",
  left: "50%",
  marginLeft: "-32px",
  marginTop: "-32px",
  zIndex: "99999"
};

export default function Dashboard_v1(props) {
  const walletConnection = useSelector((state) => state.walletConnection);
  const { id } = useParams();
  console.log(id, "idididididididid");
  const [transferAddr, setTransferAddr] = useState("");
  const [nftPrice, setNftPrice] = useState(0);
  const [userDetails, setUserDetails] = useState({});
  const [isDisable, setisDisable] = useState(false);
  const [baseUri, setBaseUri] = useState("");
  const [validation, setValidation] = useState("");
  const [nftData, setNftDAta] = useState([]);
  const [allNft, setAllNft] = useState();
  const [nftHistory, setNftHistory] = useState([]);

  useEffect(() => {
    getNftData();
    getUserDetails();
    getHistory();
  }, [walletConnection.address]);

  const getUserDetails = async () => {
    try {
      if (walletConnection.address) {
        let web3 = walletConnection.web3;
        console.log(walletConnection, "wall");
        const DesireContract = new web3.eth.Contract(
          AUTOPOOLV4,
          config.contractV4
        );
        const nftPri = await DesireContract.methods._nftPrice().call();
        console.log(nftPri, "nftPri");
        setNftPrice(Number(nftPri / 1e18));
        const Users = await DesireContract.methods
          .users(walletConnection.address)
          .call();
        console.log(Users, "Users");
        if (!Users.isExist) {
          window.location.href = "/dopamine";
        }
        setUserDetails(Users);
        const userNfts = await DesireContract.methods
          .viewUserNft(walletConnection.address)
          .call();
        setAllNft(userNfts);
      }
    } catch (err) {
      console.log(err);
    }
  };

  const getNftData = async () => {
    if (walletConnection.address) {
      let web3 = walletConnection.web3;
      let nftArr = [];
      console.log(walletConnection, "wall");
      const DesireContract = new web3.eth.Contract(
        AUTOPOOLV4,
        config.contractV4
      );

      const baseURI = await DesireContract.methods._baseTokenURI().call();
      console.log(baseURI, "baseURI", id);
      const response = await axios.get(`${baseURI + id}`);
      console.log(response, "response");
      setNftDAta({
        id: id,
        nftName: `DEZIRE NFT ${id}`,
        nft: response.data
      });
    }
  };

  const getHistory = async () => {
    try {
      if (walletConnection.address) {
        console.log("Check");
        let web3 = walletConnection.web3;
        let historyArr = [];
        let historyInves = [];
        const DesireContract = new web3.eth.Contract(
          AUTOPOOLV4,
          config.contractV4
        );
        const cliamAmt = await DesireContract.methods
          .viewWithdraw(walletConnection.address)
          .call();
        const cliamDate = await DesireContract.methods
          .viewWithdrawdate(walletConnection.address)
          .call();
        console.log(cliamAmt, cliamDate, "wqwwww");
        for (let i = 0; i < cliamAmt.length && i < cliamDate.length; i++) {
          if (Number(cliamAmt[i]) / 1e18 > 0) {
            historyArr.push({
              // sno: i + 1,
              amount: Number(cliamAmt[i]) / 1e18,
              cliamDate: Number(cliamDate[i])
            });
          }
        }
        setNftHistory(historyArr?.reverse());
      }
    } catch (err) {
      console.log(err);
    }
  };

  const handleTransfer = async () => {
    try {
      setisDisable(true);
      if (walletConnection.address) {
        console.log(walletConnection, "wall");
        let web3 = walletConnection.web3;
        const DesireContract = new web3.eth.Contract(
          AUTOPOOLV4,
          config.contractV4
        );
        if (transferAddr == "") {
          setValidation("Address is required");
          setisDisable(false);
          return false;
        }
        setValidation("");
        console.log(!web3.utils.isAddress(transferAddr), "wwwwwww");
        if (!web3.utils.isAddress(transferAddr)) {
          setValidation("Address is not valid");
          setisDisable(false);
          return false;
        }
        setValidation("");
        if (transferAddr == walletConnection.address) {
          setValidation("Self transfer restricted");
          setisDisable(false);
          return false;
        }
        setValidation("");
        const ownerOfNft = await DesireContract.methods.ownerOf(id).call();
        if (ownerOfNft !== walletConnection.address) {
          setValidation("Currently your not owner of this nft");
          setisDisable(false);
          return false;
        }
        setValidation("");
        const toUserExits = await DesireContract.methods
          .users(transferAddr)
          .call();
        if (!toUserExits.isExist) {
          setValidation("User not registered in dopamine");
          setisDisable(false);
          return false;
        }
        setValidation("");

        await DesireContract.methods
          .transfer(transferAddr, id)
          .send({ from: walletConnection.address })
          .then((res) => {
            if (res.status == true && res.transactionHash) {
              // setisDisable(false);
              toastAlert("success", "Transferred Successfully");
              setTimeout(() => {
                window.location.href = "/dopamine";
              }, 2000);
            } else {
              setisDisable(false);
              toastAlert("error", "Mint failed, try again later");
            }
          })
          .catch((err) => {
            toastAlert("error", "Transaction rejected by user");
            setisDisable(false);
            console.log(err);
          });
      } else {
        toastAlert("error", "Please Connect Your Wallet First");
        setisDisable(false);
      }
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <div>
      <Navbar />
      <div className="dashboard">
        <div className="container">
          {/* <h6 className="mb-4 pb-2" >Referral</h6> */}
          <div className="head_tab mb-4">
            <Link to="/dashboard" className="primary_btn">
              Referral Program
            </Link>
            <Link
              to="/autopooliskd728jsnak382msmakncvae"
              className="primary_btn"
            >
              Autopool1.0
            </Link>
            <Link
              to="/autopoolv2k6byep90udetbaj321krlkie"
              className="primary_btn"
            >
              Activate Autopool2.0
            </Link>
            <Link to="#" className="primary_btn">
              {" "}
              Autopool3.0
            </Link>
            <Link
              to="/dopamine"
              className="primary_btn active"
              // data-bs-toggle="modal"
              // data-bs-target="#dopamine_coming_soon"
            >
              Dopamine
            </Link>
            {/* {/* <Link to="#" className="primary_btn" data-bs-toggle="modal" data-bs-target="#gaming_coming_soon" >Game</Link> */}
            {/* <Link to="/autopool" className="primary_btn">Activate Autopool</Link> */}
          </div>
          <div className="back-link">
            <Link to="/dopamine">
              <i class="fa-solid fa-angle-left"></i> My NFT
            </Link>
          </div>
          <div className="row">
            <div className="col-lg-6">
              <div className="nft-big-image">
                {console.log(nftData, "nftData")}
                <div className="img-border">
                  <img
                    src={
                      "https://ipfs.io/ipfs/" +
                      nftData?.nft?.image.split(":")[1].replace(/^\/+/g, "")
                    }
                    alt="img"
                    className="img-fluid mx-auto"
                  />
                </div>
              </div>
            </div>
            <div className="col-lg-6">
              <div className="nft-detail-box">
                <h3>Details</h3>
                <ul>
                  <li>
                    <label>Name</label>
                    <span>{nftData.nftName}</span>
                  </li>
                  <li>
                    <label>Deposit Amount</label>
                    <span>{nftPrice ? nftPrice : 0}</span>
                  </li>
                  <li>
                    <label>Join Date</label>
                    <span>
                      {moment(Number(userDetails?.joinDate) * 1000).format(
                        "ll"
                      )}
                    </span>
                  </li>
                  <li>
                    <label>Earnings</label>
                    <span>
                      {(Number(userDetails.cliamAmount) / 1e18).toFixed(2)} USDT
                    </span>
                  </li>
                </ul>
                <h3>Transfer</h3>
                <input
                  type="text"
                  className="form-control"
                  onChange={(e) => setTransferAddr(e.target.value)}
                />
                <div
                  style={{
                    color: "#ff4141",
                    position: "relative",
                    top: "-12px",
                    float: "right"
                  }}
                >
                  {validation ? validation : ""}
                </div>
                <button className="primary_btn px-5" onClick={handleTransfer}>
                  Transfer
                </button>
              </div>
            </div>
          </div>
          <div className="bdr my-4">
            <h6 className="mb-3">Earnings History</h6>
            <div className="table_box">
              <div class="table-responsive">
                <table class="table table-borderless align-middle">
                  <thead class="table-light">
                    <tr>
                      <th>S.No</th>
                      <th>Date</th>
                      <th>Claim Amount</th>
                      {/* <th>Purchase Date</th>
                      <th>Earned Amount</th> */}
                    </tr>
                  </thead>
                  <tbody class="table-group-divider">
                    {nftHistory.length > 0 &&
                      nftHistory.map((ele, i) => {
                        return (
                          <tr>
                            <td>{i + 1}</td>
                            <td>
                              {moment(Number(ele.cliamDate) * 1000).format(
                                "ll"
                              )}
                            </td>
                            <td>{ele.amount.toFixed(2)} USDT</td>
                            {/* <td>30.01.2024</td>
                            <td>1500</td> */}
                          </tr>
                        );
                      })}
                    {/* <tr>
                      <td>NFT1</td>
                      <td>DEZIRE NFT 1</td>
                      <td>ID 12345</td>
                      <td>30.01.2024</td>
                      <td>1500</td>
                    </tr>
                    <tr>
                      <td>NFT1</td>
                      <td>DEZIRE NFT 1</td>
                      <td>ID 12345</td>
                      <td>30.01.2024</td>
                      <td>1500</td>
                    </tr> */}
                  </tbody>
                  <tfoot></tfoot>
                </table>
              </div>
            </div>
          </div>
          {/* <Footer /> */}
        </div>
      </div>
      {/* popup  dopamine */}
      <div
        className="modal fade primary_modal success"
        id="dopamine_coming_soon"
        tabIndex={-1}
        aria-hidden="true"
      >
        <div className="modal-dialog modal-lg modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-header text-center">
              <img
                src={require("../../assets/images/dopamine-coing-soon.png")}
                alt="img"
                className="img-fluid mx-auto"
              />
              <button
                type="button"
                className="btn-close absolute-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
          </div>
        </div>
      </div>
      {/* popup  gaming */}
      <div
        className="modal fade primary_modal success"
        id="gaming_coming_soon"
        tabIndex={-1}
        aria-hidden="true"
      >
        <div className="modal-dialog modal-lg modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-header text-center">
              <img
                src={require("../../assets/images/gaming-coing-soon.png")}
                alt="img"
                className="img-fluid mx-auto"
              />
              <button
                type="button"
                className="btn-close absolute-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
          </div>
        </div>
      </div>
      {isDisable && (
        <div className="loadBefore">
          <ScaleLoader
            color={"#b79c2e"}
            loading={isDisable}
            cssOverride={override}
            size={25}
            aria-label="Loading Spinner"
            data-testid="loader"
          />
        </div>
      )}
    </div>
  );
}
